import Vue from "vue";
import MainForm from "./components/MainForm.vue";
import Vuelidate from "vuelidate";
import "@/assets/css/tailwind.css";

Vue.config.productionTip = false;
Vue.use(Vuelidate);

export const mount = (el) =>
  new Vue({
    el,
    render: (h) => h(MainForm),
  });
