var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.submitted)?_c('div',[_c('h2',{staticClass:"mb-8 text-center text-primary text-h1 mt-60px"},[_vm._v(" Thank You for your Inquiry! ")]),_c('p',{staticClass:"text-center"},[_vm._v(" Someone from our business development team will be contacting you shortly. ")]),_c('p',{staticClass:"mb-3 text-center"},[_vm._v(" Don't forget to follow us on Instagram for our latest updates. ")])]):_vm._e(),(!_vm.submitted)?_c('form',{staticClass:"text-left",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h2',{staticClass:"pb-3 mb-3 content-title"},[_vm._v("General Information")]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-select',{staticClass:"col-xs-12 col-sm-6 mb25",attrs:{"tabindex":"0","name":"stylist_type","id":'stylist-type',"options":[
            { label: 'Salon', value: 0 },
            { label: 'Salon Suite', value: 'salon_suite' },
            { label: 'Independent Stylist', value: 1 },
            { label: 'Barber', value: 'barber' },
            { label: 'Esthetician', value: 'esthetician' } ],"placeholder":"Choose Your Business Type","validations":[
            {
              condition:
                _vm.$v.formData.stylist_type.$error &&
                !_vm.$v.formData.stylist_type.required,
              text: _vm.validateMessage('required', { attr: 'Business Type' }),
            } ]},model:{value:(_vm.formData.stylist_type),callback:function ($$v) {_vm.$set(_vm.formData, "stylist_type", $$v)},expression:"formData.stylist_type"}},[_vm._v(" Business Type "),_c('span',[_vm._v("*")])])],1)]),(_vm.formData.stylist_type === '0')?_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3 2 md:mb-0"},[_c('base-select',{staticClass:"col-xs-12 col-sm-6 mb25",attrs:{"tabindex":"1","name":"job_title","id":'job-title',"options":[
            { label: 'Owner', value: 'owner' },
            { label: 'Stylist', value: 'stylist' },
            { label: 'Manager', value: 'manager' },
            { label: 'Other', value: 'other' } ],"placeholder":"What's your job title?","validations":[
            {
              condition:
                _vm.$v.formData.job_title.$error &&
                !_vm.$v.formData.job_title.required,
              text: _vm.validateMessage('required', { attr: 'Job Title' }),
            } ]},model:{value:(_vm.formData.job_title),callback:function ($$v) {_vm.$set(_vm.formData, "job_title", $$v)},expression:"formData.job_title"}},[_vm._v(" Job Title "),_c('span',[_vm._v("*")])])],1)]):_vm._e(),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-input',{attrs:{"tabindex":"2","input-class":'text-black',"placeholder":"First name","autocomplete":"given-name","name":"first-name","type":"text","validations":[
            {
              condition:
                _vm.$v.formData.first_name.$error &&
                !_vm.$v.formData.first_name.required,
              text: _vm.validateMessage('required', { attr: 'First name' }),
            },
            {
              condition: !_vm.$v.formData.first_name.minLength,
              text: _vm.validateMessage('minLength', {
                attr: 'First name',
                min: 2,
              }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.first_name.$touch()}},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}},[_vm._v(" First name "),_c('span',[_vm._v("*")])])],1),_c('div',{staticClass:"w-full px-3 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"3","input-class":'text-black',"placeholder":"Last name","autocomplete":"family-name","name":"last-name","type":"text","validations":[
            {
              condition:
                _vm.$v.formData.last_name.$error &&
                !_vm.$v.formData.last_name.required,
              text: _vm.validateMessage('required', { attr: 'Last name' }),
            },
            {
              condition: !_vm.$v.formData.last_name.minLength,
              text: _vm.validateMessage('minLength', {
                attr: 'Last name',
                min: 2,
              }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.last_name.$touch()}},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}},[_vm._v(" Last name "),_c('span',[_vm._v("*")])])],1)]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-input',{attrs:{"tabindex":"4","input-class":'text-black',"placeholder":'Company',"name":"company","type":"text","validations":_vm.$v.formData.company
              ? [
                  {
                    condition:
                      _vm.$v.formData.company.$error &&
                      !_vm.$v.formData.company.required,
                    text: _vm.validateMessage('required', { attr: 'Company' }),
                  },
                  {
                    condition: !_vm.$v.formData.company.minLength,
                    text: _vm.validateMessage('minLength', {
                      attr: 'Company',
                      min: 3,
                    }),
                  } ]
              : []},on:{"blur":function($event){_vm.$v.formData.company ? _vm.$v.formData.company.$touch() : function () {}}},model:{value:(_vm.formData.company),callback:function ($$v) {_vm.$set(_vm.formData, "company", $$v)},expression:"formData.company"}},[_vm._v(" Company "),_c('span',[_vm._v(_vm._s(_vm.companyFieldRequired))])])],1),_c('div',{staticClass:"w-full px-3 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"5","input-class":'text-black',"placeholder":'Phone',"autocomplete":"tel","name":"phone","type":"text","validations":[
            {
              condition:
                _vm.$v.formData.phone.$error && !_vm.$v.formData.phone.required,
              text: _vm.validateMessage('required', { attr: 'Phone' }),
            },
            {
              condition: !_vm.$v.formData.phone.minLength,
              text: _vm.validateMessage('minLength', { attr: 'Phone', min: 5 }),
            },
            {
              condition: !_vm.$v.formData.phone.isPhone,
              text: _vm.validateMessage('isPhone', { attr: 'Phone' }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.phone.$touch()}},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}},[_vm._v(" Phone "),_c('span',[_vm._v("*")])])],1)]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-input',{attrs:{"tabindex":"6","input-class":'text-black',"placeholder":'Email',"autocomplete":"email","name":"email","type":"email","validations":[
            {
              condition:
                _vm.$v.formData.email.$error && !_vm.$v.formData.email.required,
              text: _vm.validateMessage('required', { attr: 'Email' }),
            },
            {
              condition: !_vm.$v.formData.email.email,
              text: _vm.validateMessage('email', { attr: 'Email' }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.email.$touch()}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}},[_vm._v(" Email "),_c('span',[_vm._v("*")])])],1),_c('div',{staticClass:"w-full px-3 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"7","input-class":'text-black',"placeholder":"Website","name":"url","type":"text","validations":[
            {
              condition: !_vm.$v.formData.url.url,
              text: _vm.validateMessage('url', { attr: 'Website' }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.url.$touch()},"change":function($event){return _vm.$v.formData.url.$touch()}},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}},[_vm._v(" Website ")])],1)]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-input',{attrs:{"tabindex":"8","input-class":'text-black',"placeholder":'Instagram',"name":"instagram","type":"text"},model:{value:(_vm.formData.instagram),callback:function ($$v) {_vm.$set(_vm.formData, "instagram", $$v)},expression:"formData.instagram"}},[_vm._v(" Your Instagram ")])],1),_c('div',{staticClass:"w-full px-3 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"9","input-class":'text-black',"placeholder":'Facebook',"name":"facebook","type":"text","validations":[
            {
              condition: !_vm.$v.formData.facebook.url,
              text: _vm.validateMessage('url', { attr: 'Facebook' }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.facebook.$touch()},"change":function($event){return _vm.$v.formData.facebook.$touch()}},model:{value:(_vm.formData.facebook),callback:function ($$v) {_vm.$set(_vm.formData, "facebook", $$v)},expression:"formData.facebook"}},[_vm._v(" Your Facebook URL ")])],1)]),_c('h2',{staticClass:"pb-3 mt-2 mb-3 text-primary text-h2 content-title"},[_vm._v(" Business Information ")]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-input',{attrs:{"tabindex":"10","input-class":'text-black',"placeholder":'Address Line 1',"autocomplete":"address-line1","name":"address","type":"text","validations":[
            {
              condition:
                _vm.$v.formData.address.$error && !_vm.$v.formData.address.required,
              text: _vm.validateMessage('required', { attr: 'Address line 1' }),
            },
            {
              condition: !_vm.$v.formData.address.minLength,
              text: _vm.validateMessage('minLength', {
                attr: 'Address line 1',
                min: 5,
              }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.address.$touch()}},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}},[_vm._v(" Address Line 1 "),_c('span',[_vm._v("*")])])],1)]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-input',{attrs:{"tabindex":"11","input-class":'text-black',"placeholder":'Address Line 2',"autocomplete":"address-line2","name":"address2","type":"text"},model:{value:(_vm.formData.address2),callback:function ($$v) {_vm.$set(_vm.formData, "address2", $$v)},expression:"formData.address2"}},[_vm._v(" Address Line 2 ")])],1)]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-select',{attrs:{"tabindex":"12","name":"countries","id":'countries',"options":_vm.countryOptions,"selected":_vm.formData.country,"autocomplete":"country"},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}},[_vm._v(" Country "),_c('span',[_vm._v("*")])])],1),_c('div',{staticClass:"w-full px-3 md:w-1/2"},[_c('base-select',{staticClass:"col-xs-12 col-sm-6 mb25",attrs:{"tabindex":"13","name":"state","id":'state',"options":_vm.formData.country === 'us' ? _vm.usaStateOptions : _vm.canadaStateOptions,"selected":_vm.formData.state,"autocomplete":"country"},model:{value:(_vm.formData.state),callback:function ($$v) {_vm.$set(_vm.formData, "state", $$v)},expression:"formData.state"}},[_vm._v(" State/Province "),_c('span',[_vm._v("*")])])],1)]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-input',{attrs:{"tabindex":"14","input-class":'text-black',"placeholder":'City',"autocomplete":"address-level2","name":"city","type":"text","validations":[
            {
              condition:
                _vm.$v.formData.city.$error && !_vm.$v.formData.city.required,
              text: _vm.validateMessage('required', { attr: 'City' }),
            },
            {
              condition: !_vm.$v.formData.city.minLength,
              text: _vm.validateMessage('minLength', { attr: 'City', min: 5 }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.city.$touch()}},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}},[_vm._v(" City "),_c('span',[_vm._v("*")])])],1),_c('div',{staticClass:"w-full px-3 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"15","input-class":'text-black',"placeholder":'Postal/Zip Code',"autocomplete":"postal-code","name":"postal_code","type":"text","validations":[
            {
              condition:
                _vm.$v.formData.postal_code.$error &&
                !_vm.$v.formData.postal_code.required,
              text: _vm.validateMessage('required', { attr: 'Postal/Zip code' }),
            },
            {
              condition: !_vm.$v.formData.postal_code.minLength,
              text: _vm.validateMessage('minLength', {
                attr: 'Postal/Zip code',
                min: 3,
              }),
            } ]},on:{"blur":function($event){return _vm.$v.formData.postal_code.$touch()}},model:{value:(_vm.formData.postal_code),callback:function ($$v) {_vm.$set(_vm.formData, "postal_code", $$v)},expression:"formData.postal_code"}},[_vm._v(" Postal/Zip Code "),_c('span',[_vm._v("*")])])],1)]),_c('h2',{staticClass:"pb-3 my-6 text-primary text-h2 content-title"},[_vm._v(" Professional License ")]),_c('div',{staticClass:"flex flex-wrap -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-select',{staticClass:"col-xs-12 col-sm-6 mb25",attrs:{"tabindex":"0","name":"license_type","id":'license_type',"options":[
            { label: 'Cosmetology', value: 'Cosmetology' },
            { label: 'Barber', value: 'Barber' },
            { label: 'Esthetician', value: 'Esthetician' } ],"placeholder":"License Type","validations":[
            {
              condition:
                _vm.$v.formData.license_type.$error &&
                !_vm.$v.formData.license_type.required,
              text: _vm.validateMessage('required', { attr: 'License Type' }),
            } ]},model:{value:(_vm.formData.license_type),callback:function ($$v) {_vm.$set(_vm.formData, "license_type", $$v)},expression:"formData.license_type"}},[_vm._v(" License Type "),_c('span',[_vm._v("*")])])],1),_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-input',{attrs:{"tabindex":"16","input-class":'text-black',"placeholder":'License Number',"name":"license_number","type":"text","validations":[
              {
                condition: _vm.$v.formData.license_number.$error && !_vm.$v.formData.license_number.required,
                text: _vm.validateMessage('required', { attr: 'License Number' }),
              },
              {
                condition: _vm.$v.formData.license_number.$error && !_vm.$v.formData.license_number.minLength,
                text: _vm.validateMessage('minLength', { attr: 'License Number', min: 3 })
              }
            ]},model:{value:(_vm.formData.license_number),callback:function ($$v) {_vm.$set(_vm.formData, "license_number", $$v)},expression:"formData.license_number"}},[_vm._v(" License Number * ")])],1),_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2"},[_c('base-select',{staticClass:"col-xs-12 col-sm-6 mb25",attrs:{"tabindex":"13","name":"issuing_state","id":'issuing_state',"options":_vm.formData.country === 'us' ? _vm.usaStateOptionsFull : _vm.canadaStateOptionsFull,"selected":_vm.formData.issuing_state,"autocomplete":"state","validations":[
              {
                condition: _vm.$v.formData.issuing_state.$error && !_vm.$v.formData.issuing_state.required,
                text: _vm.validateMessage('required', { attr: 'Issuing State' }),
              }
            ]},model:{value:(_vm.formData.issuing_state),callback:function ($$v) {_vm.$set(_vm.formData, "issuing_state", $$v)},expression:"formData.issuing_state"}},[_vm._v(" Issuing State * ")])],1)]),(_vm.features.reseller)?_c('h2',{staticClass:"pb-3 my-6 text-primary text-h2 content-title leading-8"},[_vm._v(" Reseller Information"),_c('br'),_c('span',{staticClass:"font-normal text-base"},[_vm._v("Optional")])]):_vm._e(),(_vm.features.reseller)?_c('div',{staticClass:"flex flex-wrap -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-input',{attrs:{"tabindex":"16","input-class":'text-black',"placeholder":'Reseller Certificate #',"name":"reseller_number","type":"text","validations":_vm.$v.formData.reseller_number
              ? [
                  {
                    condition:
                      _vm.$v.formData.reseller_number.$error &&
                      !_vm.$v.formData.reseller_number.required,
                    text: _vm.validateMessage('required', { attr: 'Reseller Number' }),
                  },
                  {
                    condition: !_vm.$v.formData.reseller_number.minLength,
                    text: _vm.validateMessage('minLength', {
                      attr: 'Reseller Number',
                      min: 3,
                    }),
                  } ]
              : []},model:{value:(_vm.formData.reseller_number),callback:function ($$v) {_vm.$set(_vm.formData, "reseller_number", $$v)},expression:"formData.reseller_number"}},[_vm._v(" Reseller Certificate # ")])],1),_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"17","input-class":'text-black',"placeholder":"Reseller Certificate","name":"reseller_certificate","type":"file","accept":".pdf, .png, .jpg, .jpeg, .tiff","validations":_vm.$v.formData.reseller_certificate
              ? [
                  {
                    condition:
                      _vm.$v.formData.reseller_certificate.$error &&
                      !_vm.$v.formData.reseller_certificate.required,
                    text: _vm.validateMessage('required', { attr: 'Reseller Certificate' }),
                  }
                ]
              : []},on:{"change":_vm.handleFileUpload},model:{value:(_vm.formData.reseller_certificate),callback:function ($$v) {_vm.$set(_vm.formData, "reseller_certificate", $$v)},expression:"formData.reseller_certificate"}},[_vm._v(" Reseller File Upload ")])],1)]):_vm._e(),_c('h2',{staticClass:"pb-3 my-6 text-primary text-h2 content-title"},[_vm._v(" Additional Information ")]),_c('div',{staticClass:"flex flex-wrap -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-select',{attrs:{"tabindex":"19","name":"source","id":'source',"options":_vm.sourceDataOptions,"placeholder":"Select an option...","validations":[
            {
              condition:
                _vm.$v.formData.source.$error && !_vm.$v.formData.source.required,
              text: _vm.validateMessage('required', { attr: 'Field' }),
            } ]},model:{value:(_vm.formData.source),callback:function ($$v) {_vm.$set(_vm.formData, "source", $$v)},expression:"formData.source"}},[_vm._v(" How did you hear about us? "),_c('span',[_vm._v("*")])])],1),_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2"},[_c('base-select',{attrs:{"tabindex":"20","name":"existing_customer","id":'existing_customer',"placeholder":"Select an option...","options":[
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 } ],"validations":[
            {
              condition:
                _vm.$v.formData.existing_customer.$error &&
                !_vm.$v.formData.existing_customer.required,
              text: _vm.validateMessage('required', { attr: 'Field' }),
            } ]},model:{value:(_vm.formData.existing_customer),callback:function ($$v) {_vm.$set(_vm.formData, "existing_customer", $$v)},expression:"formData.existing_customer"}},[_vm._v(" Are you an existing customer? "),_c('span',[_vm._v("*")])])],1)]),(_vm.sourceAddField.includes(_vm.formData.source))?_c('div',{staticClass:"flex flex-wrap -mx-3"},[_c('div',{staticClass:"w-full px-3 mt-2 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"23","input-class":'text-black',"placeholder":_vm.sourceDataTitles[_vm.formData.source],"autocomplete":"source_data","name":"source_data","type":"text","required":_vm.sourceAddField.includes(_vm.formData.source)},model:{value:(_vm.formData.source_data),callback:function ($$v) {_vm.$set(_vm.formData, "source_data", $$v)},expression:"formData.source_data"}})],1)]):_vm._e(),(_vm.formData.stylist_type === '1')?_c('div',{staticClass:"flex flex-wrap -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2 md:mb-0"},[_c('base-select',{attrs:{"tabindex":"21","name":"worked_in_salon","id":'worked_in_salon',"placeholder":"Select an option...","options":[
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 } ],"validations":[
            {
              condition:
                _vm.$v.formData.worked_in_salon.$error &&
                !_vm.$v.formData.worked_in_salon.required,
              text: _vm.validateMessage('required', { attr: 'Field' }),
            } ]},model:{value:(_vm.formData.worked_in_salon),callback:function ($$v) {_vm.$set(_vm.formData, "worked_in_salon", $$v)},expression:"formData.worked_in_salon"}},[_vm._v(" Have you previously worked with an IGK Salon? "),_c('span',[_vm._v("*")])])],1),(_vm.formData.worked_in_salon === '1')?_c('div',{staticClass:"w-full px-3 mb-6 md:w-1/2"},[_c('base-input',{attrs:{"tabindex":"22","input-class":'text-black',"placeholder":'Which salon did you work in?',"name":"salon_worked_at","type":"text"},model:{value:(_vm.formData.salon_worked_at),callback:function ($$v) {_vm.$set(_vm.formData, "salon_worked_at", $$v)},expression:"formData.salon_worked_at"}},[_vm._v(" IGK Salon Name ")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-textarea',{attrs:{"tabindex":"26","textarea-id":'comments',"value":'',"placeholder":'Comments'},model:{value:(_vm.formData.comments),callback:function ($$v) {_vm.$set(_vm.formData, "comments", $$v)},expression:"formData.comments"}},[_vm._v(" Comments ")])],1),_vm._m(0)]),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-textarea',{ref:"tos",attrs:{"has-ref":true,"name":"tos","tabindex":"27","value":_vm.legal_text,"readonly":true,"rows":8}},[_vm._v(" Terms of Service"),_c('br'),_c('sup',[_vm._v("You must read the entire agreement before the signature pad is available.")]),_c('br')]),_c('base-input',{staticClass:"mt-2",attrs:{"tabindex":"28","input-class":'text-black',"placeholder":'Please type your electronic signature here.',"name":"signature","disabled":!_vm.signature_enable,"type":"text","validations":[
              {
                condition: _vm.$v.formData.signature.$error && !_vm.$v.formData.signature.required,
                text: _vm.validateMessage('required', { attr: 'Signature' }),
              },
              {
                condition: _vm.$v.formData.signature.$error && !_vm.$v.formData.signature.minLength,
                text: _vm.validateMessage('minLength', { attr: 'Signature', min: 3 })
              }
            ]},on:{"keyup":function($event){_vm.formData.signature_date = (new Date()).toISOString()}},model:{value:(_vm.formData.signature),callback:function ($$v) {_vm.$set(_vm.formData, "signature", $$v)},expression:"formData.signature"}},[_vm._v(" By e-signing this form you affirm that you agree to our terms of service."),_c('br'),(_vm.formData.signature_ip)?_c('sup',[_vm._v("Your IP: "+_vm._s(_vm.formData.signature_ip))]):_vm._e()])],1)]),_c('div',{staticClass:"mt-2"},[_c('button-outline',{attrs:{"type":'submit',"tabindex":"27"}},[_vm._v(" Submit ")])],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full px-3"},[_c('div',{staticClass:"mt-3 text-right text-red"},[_vm._v("* Required Fields")])])}]

export { render, staticRenderFns }