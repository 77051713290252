<template>
  <div class="relative">
    <label v-if="hasDefaultSlot" :for="textareaId" class="block mb-2 font-bold"
      ><slot
    /></label>
    <textarea
      :id="textareaId"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :readonly="readonly"
      :class="[{ 'has-error pr-8': isValid === false }, inputClass]"
      :name="name"
      :ref="focus || hasRef ? name : false"
      :value="value"
      placeholder=""
      :rows="rows"
      @blur="$emit('blur')"
      @input="$emit('input', $event.target.value)"
      @keyup="$emit('keyup', $event)"
      @keyup.enter="$emit('keyup.enter', $event.target.value)"
      class="w-full p-5 focus:outline-none"
    />
    <ValidationMessages :validations="validations" v-if="validations" />
  </div>
</template>

<script>
import ValidationMessages from "./ValidationMessages.vue";
import Validation from "../mixins/Validation.js";

export default {
  name: "BaseTextarea",
  mixins: [Validation],
  components: {
    ValidationMessages,
  },
  data() {
    return {
      iconActive: false,
      icon: "visibility",
    };
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  props: {
    hasRef: {
      type: Boolean,
      required: false,
      default: false,
    },
    textareaId: {
      type: String,
      required: false,
      default: "",
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    autocomplete: {
      type: String,
      required: false,
      default: "",
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    validations: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: [Number],
      required: false,
      default: 3,
    },
  },
  mounted() {
    if (this.focus) {
      this.$refs[this.name].focus();
    }
  },
};
</script>

<style lang="css" scoped>
textarea {
  min-height: 100px;
}

.has-error {
  @apply border-red;
}
</style>
