/* eslint no-prototype-builtins: "warn" */
const messages = {
  'required': ':attr is required',
  'minLength': ':attr must have at least :min characters.',
  'maxLength': ':attr must have at least :max characters.',
  'email': ':attr must be valid email.',
  'url': ':attr must be valid url starting with https://',
  'isPhone': ':attr must be valid phone number.',
  'minOne': 'Must select at least one :attr'
}

function replacePlaceholders (str, attr) {
  let currentStr = str

  for (let k in attr) {
    currentStr = currentStr.replace(':' + k, attr[k])
  }

  return currentStr
}

export default {
  methods: {
    validateMessage: function (ruleKey, attr = {}) {
      if (typeof attr === 'object' && Object.keys(attr).length > 0) {
        // eslint-disable-next-line no-prototype-builtins
        return messages.hasOwnProperty(ruleKey) ? replacePlaceholders(messages[ruleKey], attr) : ''
      }
      // eslint-disable-next-line no-prototype-builtins
      return messages.hasOwnProperty(ruleKey) ? messages[ruleKey] : ''
    }
  }
}
