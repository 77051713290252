<template>
  <div>
    <div v-if="submitted">
      <h2 class="mb-8 text-center text-primary text-h1 mt-60px">
        Thank You for your Inquiry!
      </h2>
      <p class="text-center">
        Someone from our business development team will be contacting you
        shortly.
      </p>
      <p class="mb-3 text-center">
        Don't forget to follow us on Instagram for our latest updates.
      </p>
    </div>
    <form @submit.prevent="submit" v-if="!submitted" class="text-left">
      <h2 class="pb-3 mb-3 content-title">General Information</h2>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3">
          <base-select
            tabindex="0"
            class="col-xs-12 col-sm-6 mb25"
            name="stylist_type"
            :id="'stylist-type'"
            :options="[
              { label: 'Salon', value: 0 },
              { label: 'Salon Suite', value: 'salon_suite' },
              { label: 'Independent Stylist', value: 1 },
              { label: 'Barber', value: 'barber' },
              { label: 'Esthetician', value: 'esthetician' },
            ]"
            placeholder="Choose Your Business Type"
            v-model="formData.stylist_type"
            :validations="[
              {
                condition:
                  $v.formData.stylist_type.$error &&
                  !$v.formData.stylist_type.required,
                text: validateMessage('required', { attr: 'Business Type' }),
              },
            ]"
          >
            Business Type <span>*</span>
          </base-select>
        </div>
      </div>
      <div
        v-if="formData.stylist_type === '0'"
        class="flex flex-wrap mt-2 -mx-3"
      >
        <div class="w-full px-3 2 md:mb-0">
          <base-select
            tabindex="1"
            class="col-xs-12 col-sm-6 mb25"
            name="job_title"
            :id="'job-title'"
            :options="[
              { label: 'Owner', value: 'owner' },
              { label: 'Stylist', value: 'stylist' },
              { label: 'Manager', value: 'manager' },
              { label: 'Other', value: 'other' },
            ]"
            placeholder="What's your job title?"
            v-model="formData.job_title"
            :validations="[
              {
                condition:
                  $v.formData.job_title.$error &&
                  !$v.formData.job_title.required,
                text: validateMessage('required', { attr: 'Job Title' }),
              },
            ]"
          >
            Job Title <span>*</span>
          </base-select>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-input
            tabindex="2"
            :input-class="'text-black'"
            placeholder="First name"
            autocomplete="given-name"
            name="first-name"
            type="text"
            v-model="formData.first_name"
            @blur="$v.formData.first_name.$touch()"
            :validations="[
              {
                condition:
                  $v.formData.first_name.$error &&
                  !$v.formData.first_name.required,
                text: validateMessage('required', { attr: 'First name' }),
              },
              {
                condition: !$v.formData.first_name.minLength,
                text: validateMessage('minLength', {
                  attr: 'First name',
                  min: 2,
                }),
              },
            ]"
          >
            First name <span>*</span>
          </base-input>
        </div>
        <div class="w-full px-3 md:w-1/2">
          <base-input
            tabindex="3"
            :input-class="'text-black'"
            placeholder="Last name"
            autocomplete="family-name"
            name="last-name"
            type="text"
            v-model="formData.last_name"
            @blur="$v.formData.last_name.$touch()"
            :validations="[
              {
                condition:
                  $v.formData.last_name.$error &&
                  !$v.formData.last_name.required,
                text: validateMessage('required', { attr: 'Last name' }),
              },
              {
                condition: !$v.formData.last_name.minLength,
                text: validateMessage('minLength', {
                  attr: 'Last name',
                  min: 2,
                }),
              },
            ]"
          >
            Last name <span>*</span>
          </base-input>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-input
            tabindex="4"
            :input-class="'text-black'"
            :placeholder="'Company'"
            name="company"
            v-model="formData.company"
            @blur="
              $v.formData.company ? $v.formData.company.$touch() : () => {}
            "
            type="text"
            :validations="
              $v.formData.company
                ? [
                    {
                      condition:
                        $v.formData.company.$error &&
                        !$v.formData.company.required,
                      text: validateMessage('required', { attr: 'Company' }),
                    },
                    {
                      condition: !$v.formData.company.minLength,
                      text: validateMessage('minLength', {
                        attr: 'Company',
                        min: 3,
                      }),
                    },
                  ]
                : []
            "
          >
            Company <span>{{ companyFieldRequired }}</span>
          </base-input>
        </div>
        <div class="w-full px-3 md:w-1/2">
          <base-input
            tabindex="5"
            :input-class="'text-black'"
            :placeholder="'Phone'"
            autocomplete="tel"
            name="phone"
            type="text"
            @blur="$v.formData.phone.$touch()"
            v-model="formData.phone"
            :validations="[
              {
                condition:
                  $v.formData.phone.$error && !$v.formData.phone.required,
                text: validateMessage('required', { attr: 'Phone' }),
              },
              {
                condition: !$v.formData.phone.minLength,
                text: validateMessage('minLength', { attr: 'Phone', min: 5 }),
              },
              {
                condition: !$v.formData.phone.isPhone,
                text: validateMessage('isPhone', { attr: 'Phone' }),
              },
            ]"
          >
            Phone <span>*</span>
          </base-input>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-input
            tabindex="6"
            :input-class="'text-black'"
            :placeholder="'Email'"
            autocomplete="email"
            name="email"
            v-model="formData.email"
            @blur="$v.formData.email.$touch()"
            type="email"
            :validations="[
              {
                condition:
                  $v.formData.email.$error && !$v.formData.email.required,
                text: validateMessage('required', { attr: 'Email' }),
              },
              {
                condition: !$v.formData.email.email,
                text: validateMessage('email', { attr: 'Email' }),
              },
            ]"
          >
            Email <span>*</span>
          </base-input>
        </div>
        <div class="w-full px-3 md:w-1/2">
          <base-input
            tabindex="7"
            :input-class="'text-black'"
            placeholder="Website"
            name="url"
            @blur="$v.formData.url.$touch()"
            @change="$v.formData.url.$touch()"
            v-model="formData.url"
            type="text"
            :validations="[
              {
                condition: !$v.formData.url.url,
                text: validateMessage('url', { attr: 'Website' }),
              },
            ]"
          >
            Website
          </base-input>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-input
            tabindex="8"
            :input-class="'text-black'"
            :placeholder="'Instagram'"
            name="instagram"
            v-model="formData.instagram"
            type="text"
          >
            Your Instagram
          </base-input>
        </div>
        <div class="w-full px-3 md:w-1/2">
          <base-input
            tabindex="9"
            :input-class="'text-black'"
            :placeholder="'Facebook'"
            name="facebook"
            @blur="$v.formData.facebook.$touch()"
            @change="$v.formData.facebook.$touch()"
            v-model="formData.facebook"
            type="text"
            :validations="[
              {
                condition: !$v.formData.facebook.url,
                text: validateMessage('url', { attr: 'Facebook' }),
              },
            ]"
          >
            Your Facebook URL
          </base-input>
        </div>
      </div>
      <h2 class="pb-3 mt-2 mb-3 text-primary text-h2 content-title">
        Business Information
      </h2>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3">
          <base-input
            tabindex="10"
            :input-class="'text-black'"
            :placeholder="'Address Line 1'"
            autocomplete="address-line1"
            name="address"
            v-model="formData.address"
            @blur="$v.formData.address.$touch()"
            type="text"
            :validations="[
              {
                condition:
                  $v.formData.address.$error && !$v.formData.address.required,
                text: validateMessage('required', { attr: 'Address line 1' }),
              },
              {
                condition: !$v.formData.address.minLength,
                text: validateMessage('minLength', {
                  attr: 'Address line 1',
                  min: 5,
                }),
              },
            ]"
          >
            Address Line 1 <span>*</span>
          </base-input>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3">
          <base-input
            tabindex="11"
            :input-class="'text-black'"
            :placeholder="'Address Line 2'"
            autocomplete="address-line2"
            name="address2"
            v-model="formData.address2"
            type="text"
          >
            Address Line 2
          </base-input>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-select
            tabindex="12"
            name="countries"
            :id="'countries'"
            :options="countryOptions"
            :selected="formData.country"
            v-model="formData.country"
            autocomplete="country"
          >
            Country <span>*</span>
          </base-select>
        </div>
        <div class="w-full px-3 md:w-1/2">
          <base-select
            tabindex="13"
            class="col-xs-12 col-sm-6 mb25"
            name="state"
            :id="'state'"
            :options="
              formData.country === 'us' ? usaStateOptions : canadaStateOptions
            "
            :selected="formData.state"
            v-model="formData.state"
            autocomplete="country"
          >
            State/Province <span>*</span>
          </base-select>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-input
            tabindex="14"
            :input-class="'text-black'"
            :placeholder="'City'"
            autocomplete="address-level2"
            name="city"
            v-model="formData.city"
            @blur="$v.formData.city.$touch()"
            type="text"
            :validations="[
              {
                condition:
                  $v.formData.city.$error && !$v.formData.city.required,
                text: validateMessage('required', { attr: 'City' }),
              },
              {
                condition: !$v.formData.city.minLength,
                text: validateMessage('minLength', { attr: 'City', min: 5 }),
              },
            ]"
          >
            City <span>*</span>
          </base-input>
        </div>
        <div class="w-full px-3 md:w-1/2">
          <base-input
            tabindex="15"
            :input-class="'text-black'"
            :placeholder="'Postal/Zip Code'"
            autocomplete="postal-code"
            name="postal_code"
            v-model="formData.postal_code"
            @blur="$v.formData.postal_code.$touch()"
            type="text"
            :validations="[
              {
                condition:
                  $v.formData.postal_code.$error &&
                  !$v.formData.postal_code.required,
                text: validateMessage('required', { attr: 'Postal/Zip code' }),
              },
              {
                condition: !$v.formData.postal_code.minLength,
                text: validateMessage('minLength', {
                  attr: 'Postal/Zip code',
                  min: 3,
                }),
              },
            ]"
          >
            Postal/Zip Code <span>*</span>
          </base-input>
        </div>
      </div>

      <h2 class="pb-3 my-6 text-primary text-h2 content-title">
        Professional License
      </h2>

      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3">
          <base-select
              tabindex="0"
              class="col-xs-12 col-sm-6 mb25"
              name="license_type"
              :id="'license_type'"
              :options="[
              { label: 'Cosmetology', value: 'Cosmetology' },
              { label: 'Barber', value: 'Barber' },
              { label: 'Esthetician', value: 'Esthetician' },
            ]"
              placeholder="License Type"
              v-model="formData.license_type"
              :validations="[
              {
                condition:
                  $v.formData.license_type.$error &&
                  !$v.formData.license_type.required,
                text: validateMessage('required', { attr: 'License Type' }),
              },
            ]"
          >
            License Type <span>*</span>
          </base-select>
        </div>
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-input
              tabindex="16"
              :input-class="'text-black'"
              :placeholder="'License Number'"
              name="license_number"
              v-model="formData.license_number"
              type="text"
              :validations="[
                {
                  condition: $v.formData.license_number.$error && !$v.formData.license_number.required,
                  text: validateMessage('required', { attr: 'License Number' }),
                },
                {
                  condition: $v.formData.license_number.$error && !$v.formData.license_number.minLength,
                  text: validateMessage('minLength', { attr: 'License Number', min: 3 })
                }
              ]"
          >
            License Number *
          </base-input>
        </div>
        <div class="w-full px-3 mb-6 md:w-1/2">
          <base-select
              tabindex="13"
              class="col-xs-12 col-sm-6 mb25"
              name="issuing_state"
              :id="'issuing_state'"
              :options="formData.country === 'us' ? usaStateOptionsFull : canadaStateOptionsFull"
              :selected="formData.issuing_state"
              v-model="formData.issuing_state"
              autocomplete="state"
              :validations="[
                {
                  condition: $v.formData.issuing_state.$error && !$v.formData.issuing_state.required,
                  text: validateMessage('required', { attr: 'Issuing State' }),
                }
              ]"
          >
            Issuing State *
          </base-select>
        </div>
      </div>

      <h2 class="pb-3 my-6 text-primary text-h2 content-title leading-8" v-if="features.reseller">
        Reseller Information<br>
        <span class="font-normal text-base">Optional</span>
      </h2>

      <div class="flex flex-wrap -mx-3" v-if="features.reseller">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-input
            tabindex="16"
            :input-class="'text-black'"
            :placeholder="'Reseller Certificate #'"
            name="reseller_number"
            v-model="formData.reseller_number"
            type="text"
            :validations="$v.formData.reseller_number
                ? [
                    {
                      condition:
                        $v.formData.reseller_number.$error &&
                        !$v.formData.reseller_number.required,
                      text: validateMessage('required', { attr: 'Reseller Number' }),
                    },
                    {
                      condition: !$v.formData.reseller_number.minLength,
                      text: validateMessage('minLength', {
                        attr: 'Reseller Number',
                        min: 3,
                      }),
                    },
                  ]
                : []"
          >
            Reseller Certificate #
          </base-input>
        </div>
        <div class="w-full px-3 mb-6 md:w-1/2">
          <base-input
            tabindex="17"
            :input-class="'text-black'"
            placeholder="Reseller Certificate"
            name="reseller_certificate"
            v-model="formData.reseller_certificate"
            @change="handleFileUpload"
            type="file"
            accept=".pdf, .png, .jpg, .jpeg, .tiff"
            :validations="$v.formData.reseller_certificate
                ? [
                    {
                      condition:
                        $v.formData.reseller_certificate.$error &&
                        !$v.formData.reseller_certificate.required,
                      text: validateMessage('required', { attr: 'Reseller Certificate' }),
                    }
                  ]
                : []"
          >
            Reseller File Upload
          </base-input>
        </div>
      </div>

      <h2 class="pb-3 my-6 text-primary text-h2 content-title">
        Additional Information
      </h2>
      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-select
            tabindex="19"
            name="source"
            :id="'source'"
            :options="sourceDataOptions"
            v-model="formData.source"
            placeholder="Select an option..."
            :validations="[
              {
                condition:
                  $v.formData.source.$error && !$v.formData.source.required,
                text: validateMessage('required', { attr: 'Field' }),
              },
            ]"
          >
            How did you hear about us? <span>*</span>
          </base-select>
        </div>
        <div class="w-full px-3 mb-6 md:w-1/2">
          <base-select
            tabindex="20"
            name="existing_customer"
            :id="'existing_customer'"
            placeholder="Select an option..."
            :options="[
              { label: 'Yes', value: 1 },
              { label: 'No', value: 0 },
            ]"
            v-model="formData.existing_customer"
            :validations="[
              {
                condition:
                  $v.formData.existing_customer.$error &&
                  !$v.formData.existing_customer.required,
                text: validateMessage('required', { attr: 'Field' }),
              },
            ]"
          >
            Are you an existing customer? <span>*</span>
          </base-select>
        </div>
      </div>

      <div
        class="flex flex-wrap -mx-3"
        v-if="sourceAddField.includes(formData.source)"
      >
        <div class="w-full px-3 mt-2 md:w-1/2">
          <base-input
            tabindex="23"
            :input-class="'text-black'"
            :placeholder="sourceDataTitles[formData.source]"
            autocomplete="source_data"
            name="source_data"
            v-model="formData.source_data"
            type="text"
            :required="sourceAddField.includes(formData.source)"
          />
        </div>
      </div>

      <div v-if="formData.stylist_type === '1'" class="flex flex-wrap -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <base-select
            tabindex="21"
            name="worked_in_salon"
            :id="'worked_in_salon'"
            placeholder="Select an option..."
            :options="[
              { label: 'Yes', value: 1 },
              { label: 'No', value: 0 },
            ]"
            v-model="formData.worked_in_salon"
            :validations="[
              {
                condition:
                  $v.formData.worked_in_salon.$error &&
                  !$v.formData.worked_in_salon.required,
                text: validateMessage('required', { attr: 'Field' }),
              },
            ]"
          >
            Have you previously worked with an IGK Salon? <span>*</span>
          </base-select>
        </div>
        <div
          v-if="formData.worked_in_salon === '1'"
          class="w-full px-3 mb-6 md:w-1/2"
        >
          <base-input
            tabindex="22"
            :input-class="'text-black'"
            :placeholder="'Which salon did you work in?'"
            name="salon_worked_at"
            v-model="formData.salon_worked_at"
            type="text"
          >
            IGK Salon Name
          </base-input>
        </div>
      </div>

      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3">
          <base-textarea
            tabindex="26"
            :textarea-id="'comments'"
            :value="''"
            :placeholder="'Comments'"
            v-model="formData.comments"
          >
            Comments
          </base-textarea>
        </div>
        <div class="w-full px-3">
          <div class="mt-3 text-right text-red">* Required Fields</div>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3">
          <base-textarea :has-ref="true" ref="tos" name="tos" tabindex="27" :value="legal_text" :readonly="true" :rows="8">
            Terms of Service<br>
            <sup>You must read the entire agreement before the signature pad is available.</sup><br>
          </base-textarea>
          <base-input
              class="mt-2"
              tabindex="28"
              :input-class="'text-black'"
              :placeholder="'Please type your electronic signature here.'"
              name="signature"
              :disabled="!signature_enable"
              v-model="formData.signature"
              @keyup="formData.signature_date = (new Date()).toISOString()"
              type="text"
              :validations="[
                {
                  condition: $v.formData.signature.$error && !$v.formData.signature.required,
                  text: validateMessage('required', { attr: 'Signature' }),
                },
                {
                  condition: $v.formData.signature.$error && !$v.formData.signature.minLength,
                  text: validateMessage('minLength', { attr: 'Signature', min: 3 })
                }
              ]"
          >
            By e-signing this form you affirm that you agree to our terms of service.<br>
            <sup v-if="formData.signature_ip">Your IP: {{ formData.signature_ip }}</sup>
          </base-input>
        </div>
      </div>
      <div class="mt-2">
        <button-outline :type="'submit'" tabindex="27">
          Submit
        </button-outline>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, email, url } from "vuelidate/lib/validators";
import BaseInput from "./BaseInput.vue";
import BaseSelect from "./BaseSelect.vue";
import BaseTextarea from "./BaseTextarea.vue";
import ButtonOutline from "./ButtonOutline.vue";
// import BaseCheckbox from './BaseCheckbox.vue'
//import ValidationMessages from './ValidationMessages.vue'
import validateMessage from "../helpers/validateMessage.js";
import { validationMixin } from "vuelidate";
import { isPhone } from "@/helpers/validationRules";
import { httpClient } from "@/helpers/httpClient";
import * as filestack from "filestack-js";

export default {
  name: "MainForm",
  components: {
    BaseInput,
    BaseSelect,
    BaseTextarea,
    ButtonOutline,
    //BaseCheckbox,
    //ValidationMessages
  },
  data() {
    return {
      features: {
        reseller: false
      },
      signature_enable: false,
      legal_text: "",
      reseller_file: null,
      submitted: false,
      sourceDataOptions: [
        { label: "Behind the Chair", value: "BTC Event" },
        { label: "Salon Near Me", value: "salon" },
        { label: "A Friend", value: "friend" },
        { label: "Instagram", value: "instagram" },
        { label: "Facebook", value: "facebook" },
        { label: "Modern Salon", value: "modern-salon" },
        { label: "Salon Today", value: "salon-today" },
        { label: "Estetica", value: "estetica" },
        { label: "Beauty Launchpad", value: "beauty-launchpad" },
        { label: "Other", value: "other" },
      ],
      countries: {
        us: "United States",
        ca: "Canada",
      },
      usaStates: {
        AL: "Alabama",
        AK: "Alaska",
        AS: "American Samoa",
        AZ: "Arizona",
        AR: "Arkansas",
        CA: "California",
        CO: "Colorado",
        CT: "Connecticut",
        DE: "Delaware",
        DC: "District Of Columbia",
        FM: "Federated States Of Micronesia",
        FL: "Florida",
        GA: "Georgia",
        GU: "Guam",
        HI: "Hawaii",
        ID: "Idaho",
        IL: "Illinois",
        IN: "Indiana",
        IA: "Iowa",
        KS: "Kansas",
        KY: "Kentucky",
        LA: "Louisiana",
        ME: "Maine",
        MH: "Marshall Islands",
        MD: "Maryland",
        MA: "Massachusetts",
        MI: "Michigan",
        MN: "Minnesota",
        MS: "Mississippi",
        MO: "Missouri",
        MT: "Montana",
        NE: "Nebraska",
        NV: "Nevada",
        NH: "New Hampshire",
        NJ: "New Jersey",
        NM: "New Mexico",
        NY: "New York",
        NC: "North Carolina",
        ND: "North Dakota",
        MP: "Northern Mariana Islands",
        OH: "Ohio",
        OK: "Oklahoma",
        OR: "Oregon",
        PW: "Palau",
        PA: "Pennsylvania",
        PR: "Puerto Rico",
        RI: "Rhode Island",
        SC: "South Carolina",
        SD: "South Dakota",
        TN: "Tennessee",
        TX: "Texas",
        UT: "Utah",
        VT: "Vermont",
        VI: "Virgin Islands",
        VA: "Virginia",
        WA: "Washington",
        WV: "West Virginia",
        WI: "Wisconsin",
        WY: "Wyoming",
      },
      canadaStates: {
        AB: "Alberta",
        BC: "British Columbia",
        MB: "Manitoba",
        NB: "New Brunswick",
        NL: "Newfoundland and Labrador",
        NT: "Northwest Territories",
        NS: "Nova Scotia",
        NU: "Nunavut",
        ON: "Ontario",
        PE: "Prince Edward Island",
        QC: "Quebec",
        SK: "Saskatchewan",
        YT: "Yukon Territory",
      },
      sourceAddField: ["salon", "other"],
      sourceDataTitles: {
        salon: "Which Salon?",
        other: "How did you hear about us?",
      },
      formData: {
        terms_of_service: "",
        signature: "",
        signature_ip: "",
        signature_date: "",
        existing_customer: null,
        newsletter: 0,
        stylist_type: "",
        first_name: "",
        last_name: "",
        company: "",
        phone: "",
        email: "",
        address: "",
        address2: "",
        country: "us",
        state: "AL",
        url: "",
        instagram: "",
        facebook: "",
        comments: "",
        source: "",
        source_data: "",
        job_title: "",
        product_lines: "",
        worked_in_salon: null,
        fte: null,
        in_business_since: null,
        salon_worked_at: "",
        reseller_number: "",
        reseller_certificate: "",
        license_number: "",
        issuing_state: "",
        issuing_country: "",
        event_source: "",
        license_type: ""
      },
    };
  },
  validations() {
    let validationsData = {
      formData: {
        signature: {
          required,
          minLength: minLength(3),
        },
        stylist_type: {
          required,
        },
        license_type: {
          required,
        },
        first_name: {
          required,
          minLength: minLength(2),
        },
        last_name: {
          required,
          minLength: minLength(2),
        },
        phone: {
          required,
          minLength: minLength(5),
          isPhone,
        },
        email: {
          required,
          email,
          minLength: minLength(5),
        },
        address: {
          required,
          minLength: minLength(5),
        },
        city: {
          required,
          minLength: minLength(3),
        },
        postal_code: {
          required,
          minLength: minLength(3),
        },
        source: {
          required,
        },
        license_number: {
          required,
          minLength: minLength(3)
        },
        issuing_state: {
          required,
        },
        existing_customer: {
          required,
        },
        url: {
          url,
        },
        website: {
          url,
        },
        facebook: {
          url,
        },
      },
    };

    if (this.formData.reseller_number !== "") {
      validationsData.formData.reseller_number = {
        minLength: minLength(3)
      }

      validationsData.formData.reseller_certificate = {
        required
      }
    }

    if (
      this.formData.stylist_type !== "" &&
      this.formData.stylist_type === "0"
    ) {
      validationsData.formData.company = {
        required,
        minLength: minLength(3),
      };

      validationsData.formData.job_title = {
        required,
        minLength: minLength(3),
      };
    }

    if (
      this.formData.stylist_type !== "" &&
      this.formData.stylist_type === "1"
    ) {
      validationsData.formData.worked_in_salon = {
        required,
      };
    }

    if (this.sourceAddField.includes(this.formData.source)) {
      validationsData.formData.source_data = {
        required,
        minLength: minLength(3),
      };
    }

    return validationsData;
  },
  created() {},
  mounted() {
    if (window) {
      this.formData.event_source = (window.LBP || {}).event_source || ''
      const event = new CustomEvent("lbp/carry-us.mounted");
      window.dispatchEvent(event);
      this.fileStackInit();

      window.addEventListener('DOMContentLoaded', () => {
        let tosElm = document.querySelector("div[data-legal-tos]")

        if (tosElm) {
          this.legal_text = (tosElm.innerText || "").trim()
          this.formData.terms_of_service = this.legal_text

          let textarea = this.$refs.tos.$refs.tos;

          if (textarea.scrollHeight <= textarea.offsetHeight) {
            this.signature_enable = true
          }

          textarea.addEventListener('scroll', () => {
            let offset = textarea.offsetHeight; // height of textarea
            if (textarea.scrollHeight <= (textarea.scrollTop + offset)) {
              this.signature_enable = true
            }
          })
        }

        fetch('https://api.ipify.org?format=json')
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.formData.signature_ip = data.ip
          }).catch((err) => {
            console.log(err);
          });

      });
    }
  },
  computed: {
    getBusinessTypeValue() {
      if ([0, "salon", "salon_suite"].includes(this.formData.stylist_type)) {
        return "0"
      }

      return "1"
    },
    companyFieldRequired: function () {
      return this.formData.stylist_type === "0" ? "*" : "";
    },
    countryOptions: function () {
      return Object.keys(this.countries).map((el) => {
        return { label: this.countries[el], value: el };
      });
    },
    usaStateOptions: function () {
      return Object.keys(this.usaStates).map((el) => {
        return { label: this.usaStates[el], value: el };
      });
    },
    canadaStateOptions: function () {
      return Object.keys(this.canadaStates).map((el) => {
        return { label: this.canadaStates[el], value: el };
      });
    },
    usaStateOptionsFull: function () {
      return Object.keys(this.usaStates).map((el) => {
        return { label: this.usaStates[el], value: this.usaStates[el] };
      });
    },
    canadaStateOptionsFull: function () {
      return Object.keys(this.canadaStates).map((el) => {
        return { label: this.canadaStates[el], value: this.canadaStates[el] };
      });
    },
  },
  methods: {
    handleFileUpload( event ){
      console.log(event)
      this.reseller_file = event.target.files[0];
    },
    fileStackInit() {
      const apikey = "A2OeLU1FcSNy1hs4PinVZz";
      const client = filestack.init(apikey);

      let input = document.querySelector(
        '[data-file-upload-input="filestack"]'
      );
      if (input) {
        input.addEventListener("change", (event) => {
          const files = event.target.files;
          const file = files.item(0);
          client
            .upload(file)
            .then((res) => {
              if (res.status !== "Failed") {
                // add value to the input so it gets passed along with the formData json
                this.formData.reseller_certificate = JSON.stringify(res);
              }
            })
            .catch((err) => {
              // fail silently
              console.log(err);
            });
        });
      }
    },
    submit() {
      let formData = Object.assign({}, this.formData);

      if (formData.country === "us") {
        formData["state-" + formData.country] = formData.state;
      }

      let bodyFormData = new FormData();

      for (let key in formData) {
        if (formData[key] === null || formData[key] === "") continue;

        if (key === "stylist_type") {
          bodyFormData.append('professional_type', this.formData.stylist_type);
          bodyFormData.append('stylist_type', this.getBusinessTypeValue);
          continue;
        }

        bodyFormData.append(key, formData[key]);
      }

      // automation
      bodyFormData.append('open_active', true);
      bodyFormData.append('event_source', 'carry_us_automation');

      if (this.reseller_file) {
        bodyFormData.append('reseller_certificate', this.reseller_file);
      }

      if (this.$v.$invalid) {
        // Notification
        this.$v.$touch();
        this.scrollToError();
      } else {
        httpClient
          .post("https://data-bridge.luxbp.com/v1/lead", bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-domain': 'igkhair-pro.myshopify.com'
            }
          })
          .then((response) => {
            // handle success
            if (response.data.status === true) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "carry-us-submission",
                category: "Form",
                action: "Submit",
                label: "CarryUsFormSubmission",
              });

              if (typeof window.ga === "function") {
                window.ga("send", {
                  hitType: "event",
                  eventCategory: "Form",
                  eventAction: "Submit",
                  eventLabel: "CarryUsFormSubmission",
                });
              }
              this.submitted = true;
              window.scrollTo(0, 0);
            }
          });
      }
    },
    scrollToError() {
      setTimeout(() => {
        let errors = document.querySelectorAll(".errorMessage");
        if (errors && errors.length) {
          let top =
            window.pageYOffset + errors[0].getBoundingClientRect().top - 300;
          window.scrollTo({
            top: top,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 100);
    },
  },
  mixins: [validateMessage, validationMixin],
};
</script>

<style lang="css">
/*
label {
  font-size: 16px;
  font-family: 'agenda-bold';
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
label span {
  color: #FD818D;
}
@media screen and (min-width: 768px) {
  input {
    padding: 1.25rem;
  }
}
 */
img {
  border-width: 0;
  border-style: none;
}
</style>

<style lang="css" scoped>
/* @import "../assets/css/output.css"; */

form {
  position: relative;
}

@media screen and (min-width: 768px) {
  .md\:w-1\/2 {
    margin-top: 14px !important;
  }
}

.content-title {
  text-align: left;
  font-size: 30px !important;
  position: relative;
  margin-top: 2rem;
  font-family: "agenda-bold", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.content-title:first-child {
  margin-top: 0;
}

.content-title:before {
  display: none;
  content: "";
  position: absolute;
  width: 25px;
  height: 2px;
  bottom: 0;
  left: 0;
  border: solid 1px;
}
</style>
